<template>
  <div class="page">
    <a-row class="header">
      <a-col :span="18" class="left">
      </a-col>
      <a-col :span="6" class="right">
      </a-col>
    </a-row>
    <a-row class="main" :gutter="16">
      <a-col :span="2"></a-col>
      <a-col :span="4" class="sider">
        <div class="box"></div>
      </a-col>
      <a-col :span="12" class="middle">
        <div class="box"></div>
      </a-col>
      <a-col :span="4" class="tail">
        <div class="box"></div>
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>
    <a-row class="footer">
      <a-col :span="24">
        <div class="box">
          <a href="https://beian.miit.gov.cn/" target="_blank">黑ICP备2022003582号-1</a>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.page {
  --header-radius: 8px;
  padding: 8px;
}

.header {
  height: 64px;
}

.header .left {
  background-color: #42b983;
  border-radius: var(--header-radius) 0 0 var(--header-radius);
}

.header .right {
  background-color: brown;
  border-radius: 0 var(--header-radius) var(--header-radius) 0;
}

.main {
  margin-top: 16px;
  margin-bottom: 16px;
}

.main .box {
  height: 100vh;
  background-color: aqua;
}

.footer .box {
  height: 64px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  border-radius: var(--header-radius);
}

</style>
